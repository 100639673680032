
























































import { Component, Vue, Prop } from 'vue-property-decorator';
import DocumentApi from '@/api/document/DocumentApi';
import http from '@/interceptor/xmlHttpRequest';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer.vue';
@Component({
  name: 'DocumentAttachments',
  components: {
    ElImageViewer
  }
})
export default class DocumentAttachments extends Vue {
  // 附件列表
  @Prop({ default: [] }) attachmentList;
  // 搜索值
  searchValue: string = '';
  btnFlag: boolean = true;
  // 是否显示预览
  showPreivewDlg: boolean = false;
  previewUrl: string = '';
  showPdfOrImg: string = 'pdf';
  // 图片列表
  imgList: any = [];
  imgUrl: string = '';
  token: string = '';

  /**
   * 搜索
   */
  handleSearch() {
    this.$emit('attaSearch', this.searchValue);
  }
  created() {
    this.token = StorageUtil.getSessionStorage('token');
  }
  /**
   * 多选
   */
  handleSelectionChange(val) {
    console.log(val);
    let selectedFileID = val.map((item: any) => {
      return item.id;
    });
    if (selectedFileID.length == 0) {
      this.btnFlag = true;
    } else {
      this.btnFlag = false;
    }
  }

  downloadDo(val: any) {
    DocumentApi.attachmentsDownload({
      fileId: val.id,
      archivesId: this.$route.query.archivesId
    });
  }

  // 预览
  preview(row: any) {
    http.ajax({
      url: `/river-fs/file/view?fileId=${row.fileId}&token=${this.token}`,
      dataType: 'blob',
      timeout: 0,
      success: this.previewUploadSuccess
    });
  }

  previewUploadSuccess(result) {
    if (result.type && result.type.split('/')[0] == 'image') {
      this.showPdfOrImg = 'img';
      this.imgUrl = window.URL.createObjectURL(result);
      this.imgList = [];
      this.imgList.push(this.imgUrl);
    } else {
      this.showPdfOrImg = 'pdf';
      this.previewUrl = window.URL.createObjectURL(result); //这里调用window的URL方法
      this.showPreivewDlg = true;
    }
  }

  closeViewer() {
    this.showPdfOrImg = 'pdf';
  }
}
