<template>
  <el-card v-loading="loading" element-loading-text="资源加载中...">
    <iframe ref="iframePdf" :src="previewUrl" frameborder="0" width="100%" height="100%"></iframe>
  </el-card>
</template>

<script>
import StorageUtil from 'global-ui/packages/utils/StorageUtil';

export default {
  data() {
    return {
      baseUrl: '/river-fs/file/view?',
      previewUrl: '',
      token: '',
      loading: false
    };
  },
  props: { pdfViewId: {} },
  created() {
    this.token = StorageUtil.getSessionStorage('token');

    this.previewUrl = `/river-fs/file/view?fileId=${this.pdfViewId}&token=${this.token}#toolbar=0&navpanes=1`;
  },
  mounted() {
    this.iframeLoad();
  },
  methods: {
    iframeLoad() {
      this.loading = true;
      const iframe = this.$refs.iframePdf;
      if (iframe.attachEvent) {
        // IE
        iframe.attachEvent('onload', () => {
          this.loading = false;
        });
      } else {
        // 非IE
        iframe.onload = () => {
          this.loading = false;
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.el-card {
  border: none;
  height: 100%;
}
/deep/ .el-card__body {
  padding: 0;
  height: calc(100% - 10px);
}
</style>
