















































import { Component, Vue, Prop } from 'vue-property-decorator';
import DocumentApi from '@/api/document/DocumentApi';
import DateUtil from 'global-ui/packages/utils/DateUtil';
@Component({
  name: 'DocumentLog'
})
export default class DocumentLog extends Vue {
  @Prop({ default: {} }) currentDocumentData;
  private DateUtil: any = DateUtil;
  // tab选中
  activeName: string = 'all';
  // 表格数据
  tableListData: any = [];
  // 分页参数
  pageIndex: number = 1;
  // 每页大小
  pageSize: number = 10;
  // 总条数
  total: number = 0;
  // 当前页
  currentPage: number = 1;
  //   选中的类型
  opType: string = '';

  mounted() {
    this.getLogList();
  }

  /**
   * 获取日志列表
   */
  getLogList() {
    let params = {
      archivesId: this.currentDocumentData.id,
      opType: this.opType,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    DocumentApi.logPage(params).then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.tableListData = res.data.list;
        if (this.tableListData && this.tableListData.length > 0) {
          this.tableListData.map(item => item.title = this.currentDocumentData.objectName)
        }
        this.total = res.data.total;
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * tab切换
   */
  tabClick(val) {
    if (this.activeName == 'all') {
      this.opType = '';
    } else {
      this.opType = this.activeName;
    }
    this.getLogList();
  }

  /**
   * 改变分页大小
   */
  changePageSize(pageSize: number) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.getLogList();
  }

  /**
   * 跳转至某页
   */
  changePage(page: number) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getLogList();
  }
}
