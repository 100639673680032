<template>
  <div class="con">
    <div style="width: 100%; height: 100%" id="tinymce-editor"></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      content: ''
    };
  },
  props: {
    parentContent: {},
    tinymceValue: {}
  },
  watch: {
    tinymceValue(newVal) {
      this.setCont(newVal);
    }
  },
  methods: {
    submitCont() {
      //将获取的内容复制给textarea绑定的值
      this.content = tinymce.editors['tinymce-editor'].getContent();
    },
    setCont(val) {
      setTimeout(() => {
        tinymce.editors['tinymce-editor'].setContent(val);
      }, 200);
    }
  },
  mounted() {
    //tinymce在 index.html 使用<script>标签全局引入, 注：！！tinymce使用的是 js原生组件！！
    //@tinymce/tinymce-vue已下载 但没有使用（网上中文教程不全,英文很详细）
    if (tinymce) tinymce.remove();
    tinymce.init({
      selector: '#tinymce-editor',
      //skin:'oxide-dark',
      // language_url: '../tinymce/langs/zh_CN.js', //汉化
      language: 'zh_CN',
      menubar: false,
      plugins:
        'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave indent2em autoresize',
      toolbar: false,
      font_formats:
        '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',
      importcss_append: true,
      // 图片上传三个参数，图片数据，成功时的回调函数，失败时的回调函数
      images_upload_handler: (blobInfo, success, failure) => {
        const img = 'data:image/jpeg;base64,' + blobInfo.base64();
        success(img);
      },
      // height: '100%', 使用autoresize插件时 高度不生效
      width: '80%',
      min_height: 378,
      statusbar: false,
      toolbar_sticky: true,
      autosave_ask_before_unload: false,
      setup: (editor) => {
        if (this.tinymceValue) {
            editor.on('init', (e) => {
              editor.setContent(this.tinymceValue)
            })
        }
      }
    });
  },
  beforeDestroy() {
    // 销毁
    // tinymce.remove();
  }
};
</script>
<style lang="scss" scoped>
#tinymce-editor {
  width: 100%;
  height: 100%;
}
.con {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
</style>


