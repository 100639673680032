






































import { Component, Vue } from 'vue-property-decorator';
import DocumentApi from '@/api/document/DocumentApi';
import DocumentLog from './components/document-log.vue';
import DocumentAttachments from './components/document-attachments.vue';
import DocumentShare from './components/document-share.vue';
import DocumentProperty from './components/document-property.vue';
import TinymceEditer from '@/components/tinymce-editer/index.vue';
import PdfView from './components/pdfView.vue';
import { getDataInstanceDetail } from '@/api/form-design/FormApi';
@Component({
  name: 'DocumentDetail',
  components: {
    DocumentLog,
    DocumentAttachments,
    DocumentShare,
    DocumentProperty,
    TinymceEditer,
    PdfView
  }
})
export default class extends Vue {
  // 当前文档的id
  archivesId: any = null;
  // 当前行数据
  currentDocumentData: any = {};
  // tabs选中
  activeName: string = 'text';
  // 附件列表
  attachmentList: any = [];
  attachmentListAll: any = [];
  //文档参数
  fileParams: any = {};
  tinymceEditerParams: any = {};
  tinymceValue: string = '';
  pdfViewId: string = '';

  fliePviewType: any = null; // 2--预览富文本、 3--预(览pdf、图片等

  created() {
    console.log(this.$route.query.archivesId)
    if (this.$route.query.archivesId) {
      this.archivesId = this.$route.query.archivesId;
      this.getDocumentDeatil();
    }
  }
  mounted() {
    this.getProperty();
  }
  /**
   * tabs切换
   */
  tabClick(val: { name: string }) {
    if (val.name == 'text') this.logSave(this.archivesId, '1');
  }

  /**
   * 编辑，查看，下载，调保存日志
   * @params{ type } E: 编辑; R: 查看；D: 下载；P: 打印
   */
  logSave(id: any, type: string) {
    let params = {
      archivesId: id,
      opType: type
    };
    DocumentApi.logSave(params)
      .then((res: Record<any, any>) => { })
      .catch((e) => {
        this.$message.error(e);
      });
  }

  /**
   * 附件搜索
   */
  attaSearch(val: any) {
    if (!val) {
      return this.getAttachList();
    }
    let searchList = this.attachmentListAll.filter(
      (item: { fileName: string | any[] }) => {
        return item.fileName.includes(val);
      }
    );
    this.attachmentList = searchList;
  }
  /**
   * 获取附件列表
   */
  getAttachList() {
    let ids = [];
    if (this.currentDocumentData?.annex?.fileIds) {
      ids = this.currentDocumentData.annex.fileIds;
    } else {
      return false
    }
    DocumentApi.attachList({ ids: ids }).then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.attachmentList = res.data;
        this.attachmentListAll = res.data;
      } else {
        this.$message.error(res.message);
      }
    });
  }

  getProperty() {
    DocumentApi.getFileProPerty({ id: this.$route.query.archivesId }).then((reg: any) => {
      if (reg.code == '1') {
        this.fileParams = reg.data;
      } else {
        this.$message.error(reg.message);
      }
    });
  }
  /**
   * 获取当前文档的详情
   */
  getDocumentDeatil() {
    DocumentApi.getDetailsById({ id: this.archivesId }).then((res: Record<any, any>) => {
      console.log(res);
      console.log(res.data.type);
      if (res.code == '1') {
        this.currentDocumentData = res.data;
        let obj = {};
        if (res.data.arguments) {
          res.data.arguments.forEach((item: any) => {
            obj[item.name] = item.value;
          });
          this.currentDocumentData.obj = obj;
        }
        this.getAttachList();

        // 获取请求参数
        this.tinymceEditerParams = this.buildTinymceEditerData();
        if (
          this.tinymceEditerParams.objectId &&
          this.tinymceEditerParams.formId &&
          this.fliePviewType == '2'
        ) {
          this.getTinymceEditerData();
        }
        if (this.fliePviewType == '3') {
          this.pdfViewId = this.currentDocumentData.objectId;
        }
      } else {
        this.$message.error(res.message);
      }
    });
  }

  // 获取正文文档的数据
  getTinymceEditerData() {
    getDataInstanceDetail({
      formDataId: this.tinymceEditerParams.dataInstanceId,
      formId: this.tinymceEditerParams.formId
    }).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        res.data.map((item: { formModelCode: any; dataValue: string }) => {
          if (item.formModelCode == this.tinymceEditerParams.contentModelCode) {
            this.tinymceValue = item.dataValue;
          }
        });
      } else {
        this.$message.error(res.message);
      }
    });
  }

  // 构建 getDataInstanceDetail接口请求数据
  buildTinymceEditerData() {
    let obj: any = {};
    if (this.currentDocumentData.arguments && this.currentDocumentData.arguments.length) {
      const argument = this.currentDocumentData.arguments;
      obj.objectId = this.currentDocumentData.objectId;
      obj.dataInstanceId = this.currentDocumentData.obj.dataInstanceId;
      this.fliePviewType = this.currentDocumentData.type;
      argument.map((item: { name: string | number; value: any }) => {
        obj[item.name] = item.value;
      });
    }
    return obj;
  }
}
