var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hg-100-percent" }, [
    _c("div", { staticClass: "atta-top" }, [
      _c("div", { staticClass: "atta-left" }, [
        _vm._v(_vm._s(_vm.$t("lang_share_information"))),
      ]),
      _c("div", { staticClass: "atta-right" }),
    ]),
    _c(
      "div",
      { staticClass: "atta-bottom" },
      [
        _c(
          "el-table",
          {
            staticClass: "page-pagetable",
            attrs: {
              data: _vm.shareList,
              height: "calc(100% - 70px)",
              "empty-text": _vm.$t("lang_no_data"),
            },
          },
          [
            _c("el-table-column", {
              attrs: { label: "共享类型", align: "center", prop: "type" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: scope.row.type,
                              expression: "scope.row.type",
                            },
                          ],
                        },
                        [_vm._v("默认共享")]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !scope.row.type,
                              expression: "!scope.row.type",
                            },
                          ],
                        },
                        [_vm._v("非默认共享")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "对象类型", align: "center", prop: "deptName" },
            }),
            _c("el-table-column", {
              attrs: { label: "对象", align: "center", prop: "userName" },
            }),
            _c("el-table-column", {
              attrs: { label: "权限", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: scope.row.scope == 1,
                              expression: "scope.row.scope == 1",
                            },
                          ],
                        },
                        [_vm._v("查看")]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: scope.row.scope == 2,
                              expression: "scope.row.scope == 2",
                            },
                          ],
                        },
                        [_vm._v("下载")]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: scope.row.scope == 4,
                              expression: "scope.row.scope == 4",
                            },
                          ],
                        },
                        [_vm._v("编辑")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "下载权限", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v("可下载")])]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c("el-pagination", {
          staticClass: "page-pager",
          attrs: {
            background: "",
            "current-page": _vm.currentPage,
            "page-sizes": [10, 20, 30, 40],
            "page-size": _vm.pageSize,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "size-change": _vm.changePageSize,
            "current-change": _vm.changePage,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }