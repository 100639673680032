























































import { Component, Vue, Prop } from "vue-property-decorator";
import DocumentApi from "@/api/document/DocumentApi";
@Component({
  name: "DocumentShare",
})
export default class DocumentShare extends Vue {
  // 搜索值
  searchValue: string = "";
  //   分享列表
  shareList: any = [];
  // 分页参数
  pageIndex: number = 1;
  // 每页大小
  pageSize: number = 10;
  // 总条数
  total: number = 0;
  // 当前页
  currentPage: number = 1;

  created() {
    this.getShareList();
  }

  /**
   * 获取共享列表
   */
  getShareList() {
    let params = {
      archivesId: this.$route.query.archivesId,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      },
    };
    DocumentApi.getFileShareList(params).then((reg: any) => {
      if (reg.code == "1") {
        this.shareList = reg.data.list;
        this.total = reg.data.total;
      } else {
        this.$message.error(reg.message);
      }
    });
    // DocumentApi.logPage(params).then((res: Record<any, any>) => {
    //   if (res.code == '1') {
    //     this.shareList = res.data.list;
    //     this.total = res.data.total;
    //   } else {
    //     this.$message.error(res.message);
    //   }
    // });
  }

  /**
   * 改变分页大小
   */
  changePageSize(pageSize: number) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.getShareList();
  }

  /**
   * 跳转至某页
   */
  changePage(page: number) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getShareList();
  }
}
