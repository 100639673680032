var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hg-100-percent" }, [
    _c("div", { staticClass: "top" }, [
      _vm._m(0),
      _c("div", { staticClass: "top-left" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.currentDocumentData.objectName)),
        ]),
        _c(
          "div",
          { staticClass: "tabs" },
          [
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.tabClick },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c("el-tab-pane", {
                  attrs: { label: _vm.$t("lang_document_text"), name: "text" },
                }),
                _c("el-tab-pane", {
                  attrs: {
                    label:
                      _vm.$t("lang_document_attachments") +
                      "(" +
                      _vm.attachmentList.length +
                      ")",
                    name: "atta",
                  },
                }),
                _c("el-tab-pane", {
                  attrs: {
                    label: _vm.$t("lang_document_property"),
                    name: "prop",
                  },
                }),
                _c("el-tab-pane", {
                  attrs: {
                    label: _vm.$t("lang_document_sharing"),
                    name: "share",
                  },
                }),
                _c("el-tab-pane", {
                  attrs: { label: _vm.$t("lang_document_log"), name: "log" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm.activeName == "text" && _vm.fliePviewType == "3" && _vm.pdfViewId
      ? _c(
          "div",
          { staticClass: "bottom" },
          [_c("PdfView", { attrs: { pdfViewId: _vm.pdfViewId } })],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _vm.activeName == "text" && _vm.fliePviewType == "2" && _vm.tinymceValue
          ? _c("TinymceEditer", { attrs: { tinymceValue: _vm.tinymceValue } })
          : _vm._e(),
        _vm.activeName == "atta"
          ? _c("document-attachments", {
              attrs: { attachmentList: _vm.attachmentList },
              on: { attaSearch: _vm.attaSearch },
            })
          : _vm._e(),
        _vm.activeName == "prop"
          ? _c("document-property", { attrs: { fileParams: _vm.fileParams } })
          : _vm._e(),
        _vm.activeName == "share" ? _c("document-share") : _vm._e(),
        _vm.activeName == "log"
          ? _c("document-log", {
              attrs: { currentDocumentData: _vm.currentDocumentData },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        staticClass: "img",
        attrs: { src: require("@/assets/image/flow/人工.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }