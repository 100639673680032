var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hg-100-percent" },
    [
      _c(
        "el-descriptions",
        { staticStyle: { "margin-top": "20px" }, attrs: { border: "" } },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("lang_form_datasource_catalogue") } },
            [_vm._v(_vm._s(_vm.fileParams.catalogue))]
          ),
          _c("el-descriptions-item", { attrs: { label: "关键字" } }, [
            _vm._v(_vm._s(_vm.fileParams.keyword)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "修改" } }, [
            _vm._v(
              _vm._s(_vm.fileParams.updateByName) +
                " " +
                _vm._s(new Date(_vm.fileParams.updateTime).toLocaleString())
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "部门" } }, [
            _vm._v(_vm._s(_vm.fileParams.deptName)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "语言" } }, [
            _vm._v("简体中文"),
          ]),
          _c("el-descriptions-item", { attrs: { label: "创建" } }, [
            _vm._v(
              _vm._s(_vm.fileParams.createByName) +
                " " +
                _vm._s(new Date(_vm.fileParams.createTime).toLocaleString())
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "所有者" } }, [
            _vm._v(_vm._s(_vm.fileParams.updateByName)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }