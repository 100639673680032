import HttpUtil from '@/utils/HttpUtil';

export default {
  /**
   * 能够创建文件的文件夹
   */
  userCanCreateTree(params?: any) {
    var url = process.env.VUE_APP_SERVER_CODE + 'archives/catalogue/userCanCreateTree';
    return HttpUtil.get(url, params);
  },
  /**
   * 能够查看文件的文件夹
   */
  userReadTree(params?: any) {
    var url = process.env.VUE_APP_SERVER_CODE + 'archives/catalogue/userReadTree';
    return HttpUtil.post(url, { ...params, ...{ types: ["2", "3"] } });
  },
  /**
   * 我发布的列表
   */
  myPublishPage(params?: any) {
    var url = process.env.VUE_APP_SERVER_CODE + 'archives/myPublishPage';
    return HttpUtil.post(url, params);
  },
  /**
   * 我能查看的列表
   */
  myReadPage(params?: any) {
    var url = process.env.VUE_APP_SERVER_CODE + 'archives/myReadPage';
    return HttpUtil.post(url, params);
  },
  /**
   * 删除文件
   */
  delete(params?: any) {
    var url = process.env.VUE_APP_SERVER_CODE + 'archives/delete';
    return HttpUtil.post(url, params);
  },
  /**
   * 获取发布的表单列表
   */
  getPublishedList(params?: any) {
    var url = process.env.VUE_APP_SERVER_CODE + 'application/link/query/published';
    return HttpUtil.get(url, params);
  },
  /**
   * 获取文档详情
   */
  getDetailsById(params?: any) {
    var url = process.env.VUE_APP_SERVER_CODE + 'archives/getDetailsById';
    return HttpUtil.get(url, params);
  },
  /**
   * 保存日志
   */
  logSave(params?: any) {
    var url = process.env.VUE_APP_SERVER_CODE + 'archivesOpLog/save';
    return HttpUtil.post(url, params);
  },
  /**
   * 日志分页
   */
  logPage(params?: any) {
    var url = process.env.VUE_APP_SERVER_CODE + 'archivesOpLog/page';
    return HttpUtil.post(url, params);
  },
  /**
   * 获取附件列表
   */
  attachList(params?: any) {
    var url = '/river-fs/file/query/list';
    return HttpUtil.post(url, params);
  },
  attachmentsDownload(params?: any) {
    var url = '/river-system/archives/download';
    return HttpUtil.download(url, params);
  },
  getFileProPerty(params?: any) {
    var url = '/river-system/archives/getProPerty';
    return HttpUtil.get(url, params);
  },
  getFileShareList(params?: any) {
    var url = "/river-system/archives/getShareUser"
    return HttpUtil.post(url, params)
  }
};
