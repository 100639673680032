


















import { Component, Vue, Prop } from 'vue-property-decorator';
import DocumentApi from '@/api/document/DocumentApi';
@Component({
  name: 'DocumentProperty'
})
export default class DocumentProperty extends Vue {
  @Prop({ default: [] }) fileParams: any;
}
