var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: { "element-loading-text": "资源加载中..." },
    },
    [
      _c("iframe", {
        ref: "iframePdf",
        attrs: {
          src: _vm.previewUrl,
          frameborder: "0",
          width: "100%",
          height: "100%",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }